

// const  BACKEND_ADRESS = 'http://127.0.0.1:5757/profile/login/'
// export default BACKEND_ADRESS;
// export const
export function extractGetArguments() {
  let getComposant = window.location.hash.split('?');
  if (getComposant.length===1) { return {}; }
  getComposant = getComposant[1].split('&');
  const response = {};
  getComposant.forEach((keyValue) => {
    keyValue = keyValue.split('=');
    const key = keyValue[0];
    const value = keyValue[1];
    response[key] = value;
  });
  return response;
}
export function serializeDictToParameters(params) {
  return `/enterprise/index?${Object.entries(params).map(([k, v]) => `${k}=${v}`).join('&')}`;
}

export const BACKEND_ADRESS = ['127.0.0.1:3001', '127.0.0.1:3000', 'localhost:3001', 'localhost:3000'].indexOf(window.location.host)!==-1 ? 'http://127.0.0.1:5757' : '' ;
export const IMAGE_UPLOAD_ADRESS = `${BACKEND_ADRESS}/upload/image/`;
