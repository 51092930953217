// import axios from 'axios';
import api from 'libs/api';
import { BACKEND_ADRESS } from 'libs/index';

export default async function getMenuData() {
  return new Promise((resolve) => {
    api.get(`${BACKEND_ADRESS}/enterprise/generate_menu/`).then(e => {
      // console.log(e);
      resolve(e.data.menu);
    });
  });
}
