import axios from 'axios';

const axiosApiInstance = axios.create();

// Request interceptor for API calls
axiosApiInstance.interceptors.request.use(
  async config => {
    // const value = await redisClient.get(rediskey)
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded'
    };

    const autherUserJson = localStorage.getItem('authedUser');
    if (autherUserJson) {
      const autherUser = JSON.parse(autherUserJson);
      if (autherUser) {
        headers['authed-user-id'] = `${autherUser.id}`;
      }
    }

    config.headers = headers;
    return config;
  },
  error => {
    Promise.reject(error);
  }
);

export default axiosApiInstance;

/*
// Response interceptor for API calls
axiosApiInstance.interceptors.response.use(
  response => {
    return response
  },
  async function(error) {
    const originalRequest = error.config
    if (error.response.status === 403 && !originalRequest._retry) {
      originalRequest._retry = true
      const access_token = await refreshAccessToken()
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + access_token
      return axiosApiInstance(originalRequest)
    }
    return Promise.reject(error)
  },
)
*/
