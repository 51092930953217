// import { notification } from 'antd'
// import { history } from 'index';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { currentAccount, logout } from 'services/user';
import { GET_DATA } from '../menu/sagas';
import actions from './actions';

export function* LOGIN({ payload }) {
  // const { username, password } = payload;
  console.log('Login payload: ', payload);
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
      id: payload.id,
      name: `${payload.first_name} ${payload.last_name}`,
      email: payload.email,
      avatar: payload.avatar,
      role: payload.role,
      authorized: true,
      username: payload.username
    }
  });

  yield call(GET_DATA);
  /* const success = yield call(login, username, password);
  yield put({
    type: 'user/LOAD_CURRENT_ACCOUNT'
  });
  if (success) { */
  // console.log('Routing to home ...');
  // yield history.push('/');
  // notification.success({
  //   message: 'Logged In',
  //   description: 'You have successfully logged in to SPLASH 360!',
  // })
  // }
}

export function* LOAD_CURRENT_ACCOUNT() {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true
    }
  });
  const response = yield call(currentAccount);
  if (response) {
    const { uid: id, email, photoURL: avatar } = response;
    yield put({
      type: 'user/SET_STATE',
      payload: {
        id,
        name: 'Administrator',
        email,
        avatar,
        role: 'admin',
        authorized: true
      }
    });
  }
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false
    }
  });
}

export function* LOGOUT() {
  yield call(logout);
  yield put({
    type: 'user/SET_STATE',
    payload: {
      id: '',
      name: '',
      role: '',
      email: '',
      avatar: '',
      authorized: false,
      loading: false
    }
  });
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOGIN, LOGIN),
    takeEvery(actions.LOAD_CURRENT_ACCOUNT, LOAD_CURRENT_ACCOUNT),
    takeEvery(actions.LOGOUT, LOGOUT),
    LOAD_CURRENT_ACCOUNT() // run once on app load to check user auth
  ]);
}
